import React, { useContext, useEffect, useState } from 'react'
import fetch from 'node-fetch'
import Intercom from 'react-intercom';
import { AuthContext } from 'contexts/auth/auth.context';
import { getAuthToken, getCurrentUser, getCurrentUserUuid } from 'helper/user'
import Log from 'helper/sentry';

type IntercomProps = {
  deviceType: {
    desktop: boolean;
    tablet: boolean;
    mobile: boolean;
  }
}

const IntercomWrapper: React.FC<IntercomProps> = ({ deviceType }) => {
  const { state } = useContext<any>(AuthContext);

  const [hash, setHash] = useState(null);
  const userUuid = getCurrentUserUuid();
  
  useEffect(() => {
    if (!userUuid) return setHash(null);
    fetch(`${process.env.API_ACCOUNT_SERVICE}/intercom/hash/${userUuid}`, {
      headers: {
        'Accept': 'text/plain',
        'Devo-Api-Key': process.env.DEVO_API_KEY,
        'Devo-Auth-Token': getAuthToken(),
      }
    })
    .then(res => res.text()).then(text => {
      if (text?.length > 0) {
        setHash(text);
        Log.debug('/intercom/hash responded', 'intercom', { hash: text });
      }
    })
    .catch(err => {
      Log.warning('Error getting /intercom/hash', 'intercom', err);
    });
  }, [userUuid]);

  const intercomProps = () => {
    const user = getCurrentUser();
    if (user && hash) {
      return {
        user_id: user.uuid,
        user_hash: hash,
        email: user.email,
        name: `${user.firstName || ''} ${user.lastName || ''}`,
      }
    } else {
      return {};
    };
  };

  return <Intercom
    appID={process.env.INTERCOM_APP_ID}
    hide_default_launcher={deviceType.desktop === false}
    {...intercomProps()}
  />;
};

export default IntercomWrapper;
