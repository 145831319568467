import React, { useReducer } from 'react';
import { SearchContext } from './search.context';
type ActionType = {
  type:
    'RESET' |
    'UPDATE_POSTCODE' |
    'UPDATE_SHOP' |
    'UPDATE_CATEGORY' |
    'UPDATE_SEARCH' |
    'SET_PAGE' |
    'SHOW_RECENTS';
  payload: any;
};

function reducer(state: any, action: ActionType): any {
  switch (action.type) {
    case 'UPDATE_POSTCODE':
      return { ...state, postcode: action.payload };
    case 'UPDATE_SHOP':
      return { ...state, shop: action.payload };
    case 'UPDATE_SEARCH':
      return {
        ...state,
        search: action.payload,
        category: null,
        page: 1,
        recents: false,
      };
    case 'UPDATE_CATEGORY':
      return {
        ...state,
        category: action.payload,
        search: null,
        page: 1,
        recents: false,
      };
    case 'SHOW_RECENTS':
      return {
        ...state,
        category: null,
        search: null,
        page: 1,
        recents: action.payload
      };
    case 'RESET':
      return {
        ...state,
        search: null,
        category: null,
        page: 1,
        recents: false,
      };
    case 'SET_PAGE':
      return {
        ...state,
        page: action.payload
      }
    default:
      return state;
  }
}

export const SearchProvider: React.FunctionComponent<{ query: any }> = ({
  children,
  query = {},
}) => {
  const [state, dispatch] = useReducer(reducer, query);
  return (
    <SearchContext.Provider value={{ state, dispatch }}>
      {children}
    </SearchContext.Provider>
  );
};
