import gql from 'graphql-tag';

export const GET_LOGGED_IN_CUSTOMER = gql`
  query currentUser($userId: string) {
    auth(userId: $userId) @rest(type: "UserModel", path: "/user/{args.userId}", endpoint: "as") {
      uuid
      firstName
      lastName
      email
      phoneNumber
      joinedDate
      over18
    }
  }
`;

export const GET_MY_ADDRESSES = gql`
  query addresses($userId: string) {
    addresses(userId: $userId) @rest(type: "[UserAddress]", path: "/user/{args.userId}/addresses", endpoint: "as") {
      uuid
      userUuid
      addressLine1
      addressLine2
      postcode
      city
      state
      country
      code
      instructions
      deliveryMessage
      default
    }
  }
`;

export const GET_DELIVERABLE_ADDRESSES = gql`
  query addresses($userId: string, $shopId: string) {
    addresses(userId: $userId, shopId: $shopId) @rest(type: "[UserAddress]", path: "/address/deliverable?userUuid={args.userId}&shopUuid={args.shopId}", endpoint: "ss") {
      uuid
      userUuid
      addressLine1
      addressLine2
      postcode
      city
      state
      country
      code
      instructions
      deliveryMessage
      default
      inRange
    }
  }
`;
