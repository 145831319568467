
export const getBasket = (data) => {
  if (data && data.order && data.order.fullBasket) {
    return data.order.fullBasket.fullBasketItems;
  }
  return [];
}

// DATE

export const formatDate = (str) => {
  const date = new Date(str)
  const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' })
  const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat.formatToParts(date)

  return `${day} ${month} ${year}`
}

// PRICES

export const formatPrice = (price) => {
  if (!price) return "£0.00";
  var formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });

  return formatter.format(price);
}

export const getFullBasketPrice = (data) => {
  if (!data) return null;
  var total = 0;
  getBasket(data).forEach( record => {
    if (record.shopProduct) {
      const price = record.quantity * record.shopProduct.devoPrice;
      total = total + price;
    } else if (record.replacementDevoPrice) {
      total = total + record.replacementDevoPrice;
    }
  });
  return formatPriceNumber(total);
}

export const getPartialBasketPrice = (data) => {
  if (!data) return null;
  if (!data.totalPrice || !data.serviceFee || !data.deliveryFee) return null;
  const price = data.totalPrice - data.serviceFee - data.deliveryFee;
  return formatPriceNumber(price);
}

export const getDiscount = (data, item) => {
  const basketPrice = getFullBasketPrice(data) || getPartialBasketPrice(item);
  const discount = -item.totalPrice + basketPrice + item.deliveryFee + item.serviceFee;
  return formatPriceNumber(discount);
}

const formatPriceNumber = (amount) => {
  return Math.round((amount + Number.EPSILON) * 100) / 100
}

// ADDRESS

export const getFormattedAddress = (item: any) => {
  var address = '';
  address = addAddressIfOk(item, 'instructions', address);
  address = addAddressIfOk(item, 'deliveryMessage', address);
  address = addAddressIfOk(item, 'addressLine1', address);
  address = addAddressIfOk(item, 'addressLine2', address);
  address = addAddressIfOk(item, 'city', address);
  address = addAddressIfOk(item, 'postcode', address);
  return address;
}

const addAddressIfOk = (item: any, property: string, old: string): string => {
  var address = old + '';
  if (item[property] && item[property].length > 0) {
    if (address.length > 0) {
      address = address + ', ';
    }
    address = address + item[property];
  }
  return address
}

// ORDER STATUS

/* CONFIRMED, PAYMENT_ERROR, REQUESTING_RIDER, RIDER_TO_SHOP, RIDER_TO_USER, DELIVERED, COMPLETED, FLEET_ERROR, CANCELED */

export const statusLabel = (status: String) => {
  if (!status) return "";

  switch (status) {
    case 'CHECKOUT':
      return 'CONFIRMING';
    case 'REQUESTING_RIDER':
    case 'FLEET_ERROR':
      return 'FINDING RIDER';
    case 'RIDER_TO_USER':
      return 'RIDER TO YOU';
    default:
      return status.replace(/_/g, " ");
  }
};

export const statusColors = (status: String) => {
  if (!status) return ['#000', '#FFF'];

  switch (status) {
    case 'CHECKOUT':
    case 'CONFIRMED':
    case 'REQUESTING_RIDER':
    case 'RIDER_TO_SHOP':
    case 'RIDER_TO_USER':
    case 'FLEET_ERROR':
    case 'SCHEDULED':
      return ['#2E70FA', '#E3E9F7'];
    case 'COMPLETED':
    case 'DELIVERED':
      return ['#7ED321', '#EBF3E1'];
    case 'PAYMENT_ERROR': 
    case 'CANCELED':
      return ['#FF3B30', '#F8E4E3'];
    default:
      return ['#000', '#FFF'];
  }
}

export const TRACKER_MESSAGES = ['Requesting a rider', 'Rider is on their way to shop.', 'Rider is on their way to you.', 'Delivered'];

export const IN_PROGRESS = ['CHECKOUT', 'CONFIRMED', 'REQUESTING_RIDER', 'RIDER_TO_SHOP', 'RIDER_TO_USER', 'FLEET_ERROR', 'SCHEDULED'];
export const SHOW_MAP = ['RIDER_TO_SHOP', 'RIDER_TO_USER', 'DELIVERED'];

export const TRACKER_STATUS_REF = {
  'CONFIRMED': 0,
  'SCHEDULED': 0,
  'REQUESTING_RIDER': 1,
  'FLEET_ERROR': 1,
  'RIDER_TO_SHOP': 2,
  'RIDER_TO_USER': 3,
  'DELIVERED': 4,
  'COMPLETED': 4
};

export const ORDER_STATUS_MESSAGES = {
  'CONFIRMED': {
    title: "Your order is confirmed.",
  },
  'PAYMENT_ERROR': {
    title: "We couldn't take payment for this order.",
    subtitle: 'Please contact us to get more information.',
  },
  'CANCELED': {
    title: "Uh-oh! Delivery cancelled 😩",
    subtitle: "Your delivery was cancelled. Contact us to get more information."
  },
  'DELIVERED': {
    title: "Delivery complete 🎉",
    subtitle: "Enjoy your order and see you soon!"
  }
};