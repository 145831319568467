import styled, { keyframes } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import DevoWhiteBird from 'image/devo-bird-white.png';

const positionAnim = keyframes`
  from {
    position: fixed;
    opacity: 1;
  }

  to {
    opacity: 0;
    transition: all 0.3s ease;
  }
`;

const hideSearch = keyframes`
  from {
    display: none;
  }

  to {
    display: flex;
  }
`;

export const SearchWrapper = styled.div`
  padding: 5px;
  cursor: pointer;
  color: ${themeGet('colors.darkBold', '#0D1136')};
  svg {
    display: block;
    width: 17px;
    height: auto;
  }

  @media only screen and (min-width: 991px) and (max-width: 1366px) {
    opacity: 0;
  }
`;

const TransparentHeader = `
  &.transparent {
    background-color: transparent;
    box-shadow: none;
    
    .logo {
      display: none;
    }

    .login {
      background-color: #ee504f !important;
      border-color: #fff !important;
      color: #fff !important;
    }

    .register {
      background-color: #fff !important;
      color: #ee504f !important;
    }

    .hamburger span {
      background-color: white;
    }
  }

  &.home {
    .login {
      background-color: #f59412 !important;
      border-color: #fff !important;
      color: #fff !important;
    }

    .register {
      background-color: #fff !important;
      color: #f59412 !important;
    }
  }
`;

const HeaderWrapper = styled.header`
  padding: 30px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;

  @media (max-width: 1400px) {
    padding: 25px 40px;
  }

  @media (max-width: 990px) {
    display: none;
  }

  .headerSearch {
    margin: 0 30px;

    @media only screen and (min-width: 991px) and (max-width: 1200px) {
      margin: 0 15px;

      input {
        width: 80%;
      }

      .buttonText {
        display: none;
      }
    }
  }

  &.unSticky {
    animation: ${positionAnim} 0.3s ease;
    .headerSearch {
      animation: ${hideSearch} 0.3s ease;
      display: none;
    }
  }

  &.sticky {
    background-color: #ffffff;
    position: fixed;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
    padding-top: 20px;
    padding-bottom: 20px;

    @media (max-width: 1400px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .headerSearch {
      display: flex;

      form {
        background-color: ${themeGet('colors.lightMediumColor', '#F3F3F3')};

        input {
          background-color: ${themeGet('colors.lightMediumColor', '#F3F3F3')};
        }
      }

      @media only screen and (min-width: 991px) and (max-width: 1200px) {
        .buttonText {
          display: none;
        }
      }
    }
  }

  .popover-wrapper {
    .popover-content {
      padding: 10px 0;

      .menu-item {
        a {
          margin: 0;
          padding: 12px 30px;
          border-bottom: 1px solid ${themeGet('colors.lightColor', '#F7F7F7')};
          cursor: pointer;
          white-space: nowrap;

          &:last-child {
            border-bottom: 0;
          }
          &:hover {
            color: ${themeGet('colors.primary', '#EE504F')};
          }
          &.current-page {
            color: ${themeGet('colors.primary', '#EE504F')};
          }

          .menu-item-icon {
            margin-right: 15px;
          }
        }
      }
    }
  }

  .headerSearch {
    input {
      @media (max-width: 1400px) {
        padding: 0 15px;
        font-size: 15px;
      }

      @media only screen and (min-width: 991px) and (max-width: 1200px) {
      }
    }
    button {
      @media (max-width: 1400px) {
        padding: 0 15px;
        font-size: 15px;
      }
    }
  }

  ${TransparentHeader}
`;

export const HeaderLeftSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
`;

export const HeaderRightSide = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  .menu-icon {
    min-width: 14px;
    margin-right: 7px;
  }

  .menu-item {
    a {
          font-size: ${themeGet('fontSizes.2', '15')}px;
      font-weight: ${themeGet('fontWeights.6', '700')};
      color: ${themeGet('colors.darkBold', '#0D1136')};
      line-height: 1.2em;
      display: block;
      transition: 0.15s ease-in-out;
      display: flex;
      align-items: center;
      margin-right: 45px;

      @media (max-width: 1400px) {
        margin-right: 35px;
        font-size: 15px;
      }
      &:hover {
        color: ${themeGet('colors.primary', '#EE504F')};
      }
      &.current-page {
        color: ${themeGet('colors.primary', '#EE504F')};
      }
    }
  }

  .user-pages-dropdown {
    .popover-handler {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      display: block;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    .popover-content {
      .inner-wrap {
        /* padding: ; */
      }
    }
  }
`;

export const MainMenu = styled.div`
  display: flex;
  align-items: center;

  .popover-wrapper {
    .popover-content {
      .menu-item {
              a {
          font-size: 15px;
          font-weight: 800;
          color: ${themeGet('colors.darkBold', '#0D1136')};
          line-height: 1.2em;
          display: block;
          padding: 15px 30px;
          border-radius: 6px;
          transition: 0.15s ease-in-out;
          display: flex;
          align-items: center;
          @media (max-width: 1400px) {
            margin-right: 10px;
            font-size: 15px;
          }

          @media only screen and (min-width: 991px) and (max-width: 1200px) {
            padding: 15px 30px;
          }

          &:hover {
            color: ${themeGet('colors.primary', '#EE504F')};
          }
          &.current-page {
            color: ${themeGet('colors.primary', '#EE504F')};
            background-color: #fff;
          }
        }
      }
    }
  }
`;

export const MobileHeaderWrapper = styled.div`
  @media (min-width: 991px) {
    .desktop {
      display: none;
    }
  }
`;

export const SelectedType = styled.button`
  width: auto;
  height: 38px;
  display: flex;
  align-items: center;
  background-color: ${themeGet('colors.white', '#ffffff')};
  border: 1px solid ${themeGet('colors.borderColor', '#f1f1f1')};
  padding-top: 0;
  padding-bottom: 0;
  padding-left: ${themeGet('space.4', '15')}px;
  padding-right: ${themeGet('space.4', '15')}px;
  border-radius: ${themeGet('radius.3', '6')}px;
  outline: 0;
  min-width: 150px;
  cursor: pointer;

  span {
    display: flex;
    align-items: center;
      font-size: ${themeGet('fontSizes.2', '15')}px;
    font-weight: ${themeGet('fontWeights.6', '700')};
    color: ${themeGet('colors.primary', '#EE504F')};
    text-decoration: none;

    &:first-child {
      margin-right: auto;
    }
  }
`;

export const DropDownArrow = styled.span`
  width: 12px;
  margin-left: 16px;
`;

export const MobileHeaderInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  transition: 0.25s ease-in-out;

  &.unSticky:not(.home) {
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
  }

  &.sticky {
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);

    @media only screen and (min-width: 991px) and (max-width: 1366px) {
      .searchIconWrapper {
        opacity: 1;
      }
    }
  }

  ${TransparentHeader}
`;

export const DrawerWrapper = styled.div`
  width: 26px;

  &.black {
    .hamburger span {
      background-color: #000;
    }
  }
`;

export const LogoWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  img {
    height: 35px;
    display: block;
  }
`;

export const Spacer = styled.div`
  height: 1px;
  flex-grow: 2;
`;

export const HamburgerIcon = styled.div`
  width: 26px;
  cursor: pointer;
  display: block;
  position: relative;
  > span {
    display: block;
    background-color: ${themeGet('colors.darkBold', '#0D1136')};
    border-radius: 6px;
    height: 2px;
    margin-bottom: 6px;

    transition: 0.2s ease-in-out;
    &:nth-child(1) {
      width: 26px;
    }
    &:nth-child(2) {
      width: 12px;
    }
    &:nth-child(3) {
      width: 22px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &:hover {
    > span {
      &:nth-child(1) {
        width: 12px;
      }
      &:nth-child(2) {
        width: 22px;
      }
      &:nth-child(3) {
        width: 26px;
      }
    }
  }
`;

export const DrawerContentWrapper = styled.div`
  padding-top: 45px;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
`;

export const DrawerClose = styled.div`
  display: block;
  position: absolute;
  left: 20px;
  top: 15px;
  color: #ee504f;
  cursor: pointer;
  padding: 10px;
  z-index: 1;
  svg {
    display: block;
    width: 12px;
    height: auto;
  }
`;

export const AuthenticationButtons = styled.div`
  a {
    display: block;
    text-align: center;
    padding: 15px;
    font-family: Inter, Helvetica Neue, sans-serif;
    border-radius: 4px;
    margin-bottom: 10px;
  }
`;

export const LoginButton = styled.a`
  border: 2px #ee504f solid;
  color: #ee504f;
`;

export const RegisterButton = styled.a`
  background-color: #ee504f;
  color: #fff;
`;

export const DrawerProfile = styled.div`
  padding: 20px;
`;

export const LoginView = styled.div`
  display: flex;
  align-items: center;
`;

export const UserAvatar = styled.div`
  width: 40px;
  height: px;
  flex-shrink: 0;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

export const UserDetails = styled.div`
  h3 {
    font-size: 16px;
    margin-bottom: 3px;
  }
  a {
    display: block;
    font-size: 14px;
    color: #ee504f;
  }
`;

export const DrawerMenu = styled.div`

`;

export const DrawerMenuItem = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0;
  cursor: default;
  
  &:first-child {
    margin-top: 20px;
  }
  
  svg {
    width: 20px !important;
    margin: 0 10px 0 30px;
  }
  
  a {
    cursor: pointer;
    font-size: 16px;
    color: #000;
    
    span {
      font-size: 14px;
      color: #aeaeae;
    } 
  }
  
  &.current-page {
    svg {
      color: #ee504f;
    }
    
    a {
      color: #ee504f;
    }
  }
  
  .logout {
    margin-left: 30px;
    color: #616161;
  }
`;

export const DrawerSpacer = styled.div`
  width: 100%;
  flex-grow: 2;
`;

const AppDemoWrapper = styled.a`
  display: flex;
  align-items: center;
  margin: 20px 5px 25px 10px;
`;

const AppDemoIcon = styled.div`
  display: inline-block;
  width: 60px;
  height: 60px;
  border: 1px #eaeaea solid;
  border-radius: 12px;
  background: #ee504f url(${DevoWhiteBird}) no-repeat;
  background-size: 65%;
  background-position: center;
`;

const AppDemoText = styled.div`
  display: inline-block;
  width: calc(100% - 60px);
  vertical-align: top;
  
  div {
    margin-left: 10px;
  }
  
  p {
    align-self: center;
    font-family: Inter, Helvetica Neue, sans-serif;
    font-size: 16px;
    color: #000;
  }
  
  span {
    font-size: 12px;
    font-weight: 600;
    color: #ee504f;
  }
  
  svg {
    height: 10px;
    margin-left: 2px;
  }
`;

const AppDemoBadges = styled.div`
  margin: 10px;
`;

export const AppDemo = {
  Wrapper: AppDemoWrapper,
  Badges: AppDemoBadges,
  Icon: AppDemoIcon,
  Text: AppDemoText,
};

// SEARCH

export const SearchModalWrapper = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #ffffff;
  display: flex;
  width: 100%;

  .header-modal-search {
    width: calc(100% - 60px);
    box-shadow: none;

    button {
      background: transparent;
      color: ${themeGet('colors.darkBold', '#0D1136')};
      padding-left: 15px;
      padding-right: 15px;
    }
    input {
      color: ${themeGet('colors.darkBold', '#0D1136')};
      padding-left: 5px;
      padding-right: 5px;
    }
  }
`;

export const SearchModalClose = styled.button`
  border: 0;
  background: transparent;
  display: block;
  padding: 0 15px;
  color: ${themeGet('colors.darkBold', '#0D1136')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    display: block;
    width: 20px;
    height: auto;
  }
`;

export default HeaderWrapper;
