import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  background-color: #f7f7f7;

  .reuseModalHolder {
    background-color: #fff;
    padding: 0;
    overflow: auto;
    border-radius: 6px;
    border: 0;
  }

  .clear-modal {
    background-color: transparent;
  }

  .add-contact-modal {
    overflow: visible;
  }
`;