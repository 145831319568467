import InputSearch from './ProductInput';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import SearchResults from './SearchResults';
import {
  HeaderSearchWrapper,
  SearchBoxWrapper,
} from './SearchBox.style';
import { SearchIcon } from '../AllSvgIcon';
import { logAnalytics } from 'helper/gtm/helper';

type SearchBoxProps = {
  buttonText?: string;
  buttonIcon?: React.ReactNode;
  inputStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  bordered?: boolean;
  hideType?: boolean;
  showSvg?: boolean;
  expand?: boolean;
  minimal?: boolean;
  placeholder?: string;
  className?: string;
  handleSearch?: Function;
  onClick?: Function;
  value?: any;
  pathname?: string;
};

const ProductSearchBox: React.FC<SearchBoxProps> = ({
  buttonText,
  buttonIcon,
  inputStyle,
  style,
  bordered,
  hideType,
  showSvg,
  className,
  handleSearch,
  onClick,
  value,
  expand,
  minimal,
  pathname,
  placeholder
}) => {
  const router = useRouter();
  const [searchValue, setSearchValue] = useState(value);
  const [toggleSearch, setToggleSearch] = useState(false);

  let searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    if (!value || value?.length === 0) setSearchValue('');
  }, [value]);

  const handleSearchInput = (event: any) => {
    setSearchValue(event.target.value);
    handleSearch(event.target.value);
  };

  const ucwords = (str: string) => {
    const newString = str.replace(/\//g, '');
    const humanString = newString.replace(/-/g, ' ');
    return (humanString + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
      return $1.toUpperCase();
    });
  };

  const handleClickOutside = (event: any) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setToggleSearch(false);
    }
  };

  return (
    <HeaderSearchWrapper
      className={className}
      ref={searchRef}
      style={style}
      hideType={hideType}
      expand={expand}
    >
      <SearchBoxWrapper
        className={`${hideType ? 'hideType' : ''} ${
          expand === true ? (toggleSearch ? 'expanded' : 'collapsed') : ''
        } ${minimal === true ? 'minimal' : ''}`}
      >
        <InputSearch
          type="text"
          value={searchValue}
          onChange={handleSearchInput}
          onFocus={() => {
            setToggleSearch(true);
            logAnalytics('open_search');
          }}
          onBlur={() => {
            setToggleSearch(false);
            logAnalytics('close_search');
          }}
          buttonIcon={buttonIcon}
          buttonText={buttonText}
          style={inputStyle}
          bordered={bordered}
          showSvg={showSvg}
          onClick={() => onClick(searchValue)}
          placeholder={placeholder}
        />
      </SearchBoxWrapper>
    </HeaderSearchWrapper>
  );
};

ProductSearchBox.defaultProps = {
  bordered: false,
  buttonText: 'Search',
  buttonIcon: <SearchIcon />,
  inputStyle: {
    width: '100%',
  },
};

export default ProductSearchBox;
