import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { openModal, closeModal } from "@redq/reuse-modal";
import { newReferralClick } from 'helper/gtm/promotions'
import { DrawerMenuItem } from '../Header.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faReceipt, faGift } from '@fortawesome/free-solid-svg-icons';
import { getCurrentUserUuid } from 'helper/user';
import { useQuery } from '@apollo/react-hooks';
import { GET_LOGGED_IN_CUSTOMER } from 'graphql/query/customer.query';
import { GET_MY_REFERRAL } from 'graphql/query/referral.query';
import { ORDERS_ROUTE, PROFILE_ROUTE } from 'constants/navigation';
import { logAnalytics } from 'helper/gtm/helper';
const ReferralPromotion = dynamic(import("containers/ReferralPromotion/ReferralPromotion"));

const MenuItem: React.FC<{ index: number, label: string, subtitle?: string, icon?: any, onClick?: any }> = ({ index, icon, label, subtitle, onClick }) => {
  return (
    <DrawerMenuItem key={index}>
        <a onClick={onClick}>
          {icon && <FontAwesomeIcon icon={icon} />}
        </a>
        <a
          onClick={onClick}
          className={icon ? '' : 'logout'}
        >
          {label}
          <br/>
          {subtitle &&
            <span>{subtitle}</span>
          }
        </a>
      </DrawerMenuItem>
  )
};

type Props = {
  onLinkClick: () => void;
  onLogout: () => void;
};

export const AuthorizedMenu: React.FC<Props> = ({ 
  onLinkClick, 
  onLogout,
}) => {
  const router = useRouter();

  const userId = getCurrentUserUuid();
  const { data } = useQuery(GET_LOGGED_IN_CUSTOMER, {
    skip: !userId,
    variables: { userId: userId },
  });
  const user = data?.auth;

  const { data: referralData } = useQuery(GET_MY_REFERRAL, {
    skip: !userId,
    variables: {
      sourceUuid: userId
    },
  });
  const referral = referralData?.referral;

  const handleLinkClick = (route?: string) => {
    onLinkClick();
    if (route) router.push(route);
  };

  const handlePromotion = (e: any) => {
    onLinkClick();
    newReferralClick('user_account_screen');
    openModal({
      config: {
        height: 'auto',
      },
      closeOnClickOutside: true,
      component: ReferralPromotion,
      componentProps: { closeModal },
      closeComponent: 'div',
    });
  };

  const handleLogout = (e: any) => {
    onLinkClick();
    onLogout();
  };

  return (
    <>
      {user?.firstName ? (
        <MenuItem
          index={0}
          icon={faUserCircle}
          label={user.firstName}
          subtitle={'View Profile'}
          onClick={() => {
            handleLinkClick(PROFILE_ROUTE);
            logAnalytics('account');
          }}
        />
      ) : (
        <MenuItem
        index={0}
          icon={faUserCircle}
          label={'Profile'}
          onClick={() => {
            handleLinkClick(PROFILE_ROUTE);
            logAnalytics('account');
          }}
        />
      )}
      <MenuItem
        index={1}
        icon={faReceipt}
        label='Orders'
        onClick={() => {
          handleLinkClick(ORDERS_ROUTE);
          logAnalytics('account_nav_orders');
        }}
      />
      {referral?.active === true &&
        <MenuItem
          index={2}
          icon={faGift}
          label='Invite Friends'
          subtitle={`Give £${referral.rewardAmount}, Get £${referral.creditAmount}`}
          onClick={handlePromotion}
        />
      }
      <MenuItem
        index={3}
        label='Log Out'
        onClick={handleLogout}
      />
    </>
  );
};
