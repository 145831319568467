import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  Wrapper,
  ImageWrapper,
  PriceWrapper,
  TotalPrice,
} from './HeaderBasket.style';
import { ShoppingBag } from '../AllSvgIcon';
import Loader from 'components/Loader/Loader';

import { GET_SHOP } from 'graphql/query/shops.query';
import { useCart } from 'contexts/cart/use-cart';
import { formatPrice } from 'helper/order';

type HeaderBasketProps = {
  className?: string;
}

const HeaderBasket: React.FC<HeaderBasketProps> = ({ className }) => {
  const {
    items,
    postcode,
    calculateSubTotalPrice,
  } = useCart();

  const shopId = items[0]?.shopUuid;
  const { data, loading } = useQuery(GET_SHOP, {
    skip: !shopId,
    variables: { shopId: shopId },
  });

  // ACTIONS

  const shopLink = () => {
    const prettyId = data?.shop?.prettyId;
    if (!prettyId) return null;
    if (prettyId) {
      if (postcode) {
        return `/shop/${prettyId}?postcode=${postcode}`;
      } else {
        return `/shop/${prettyId}`;
      }
    } else {
      return null;
    }
  };

  // RENDER

  if (items.length === 0) return <></>;

  if (loading || !data) {
    return (
      <Wrapper>
        <Loader style={{ margin: '0 auto' }} />
      </Wrapper>
    );
  }

  return (
    <Wrapper
      href={ shopLink() }
      className={ className ? className : '' }
    >
      <ImageWrapper>
        <ShoppingBag />
      </ImageWrapper>
      <PriceWrapper>
        <TotalPrice>{ formatPrice(calculateSubTotalPrice()) }</TotalPrice>
      </PriceWrapper>
    </Wrapper>
  )
};

export default HeaderBasket;
