import React, { useState, useEffect, useRef } from 'react';
import useOnClickOutside from './useOnClickOutside.js';
import PopoverWrapper from './Popover.style';

type PopoverProps = {
  className?: string;
  handler: React.ReactNode;
  content: React.ReactNode;
  direction?: 'left' | 'right';
  state: boolean;
  onToggle: (state: boolean) => void;
};

const Popover: React.FC<PopoverProps> = ({
  className,
  handler,
  content,
  direction,
  state,
  onToggle
}) => {
  // Ref
  const ref = useRef();

  // Add all classs to an array
  const addAllClasses: string[] = ['popover-wrapper'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  // Add direction class on popover content
  if (direction) {
    addAllClasses.push(direction);
  }

  // Toggle Popover content
  const handleToggle = e => {
    e.stopPropagation();
    onToggle(!state);
  };

  // Handle document click
  const handleDocumentClick = e => {
    e.stopPropagation();
  };

  // Handle window event listener
  useEffect(() => {
    window.addEventListener('click', handleDocumentClick);
    return () => {
      window.removeEventListener('click', handleDocumentClick);
    };
  });


  useOnClickOutside(ref, () => {
    onToggle(false);
  });

  return (
    <PopoverWrapper className={addAllClasses.join(' ')} ref={ref}>
      <div className='popover-handler' onClick={handleToggle}>
        {handler}
      </div>
      {state && (
        <div className='popover-content'>
          {content && (
            <div className='inner-wrap'>
              {content}
            </div>
          )}
        </div>
      )}
    </PopoverWrapper>
  );
};

export default Popover;
