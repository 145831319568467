
type HeaderStyle = {
  eligible: boolean;
  wrapperClassName: string;
  showLogo: boolean;
  showRightMenu: boolean;
  whiteLogo: boolean;
};

export const useHeaderStyle = (pathname: string, scroll: number, mobile: boolean = false): HeaderStyle => {
  if (pathname.includes('/category/')) return {
    eligible: scroll > -250,
    wrapperClassName: 'transparent',
    showLogo: true,
    showRightMenu: true,
    whiteLogo: scroll > -250,
  };

  if (pathname.includes('/area/')) return {
    eligible: scroll > -100,
    wrapperClassName: 'transparent',
    showLogo: true,
    showRightMenu: true,
    whiteLogo: scroll > -250,
  };

  if (!mobile && pathname.includes('/shops/')) return {
    eligible: scroll > -50,
    wrapperClassName: 'transparent home',
    showLogo: true,
    showRightMenu: true,
    whiteLogo: scroll > -50,
  };

  switch (pathname) {
    case '/': return {
      eligible: scroll > -250,
      wrapperClassName: 'transparent home',
      showLogo: true,
      showRightMenu: true,
      whiteLogo: scroll > -250,
    };

    case '/referral': return {
      eligible: true,
      wrapperClassName: 'transparent referral',
      showLogo: false,
      showRightMenu: false,
      whiteLogo: true,
    };

    case '/referral/app': return {
      eligible: true,
      wrapperClassName: 'transparent home',
      showLogo: true,
      showRightMenu: false,
      whiteLogo: true,
    };

    case '/integrations/morley-house':
    case '/integrations/canvas':
    case '/integrations/chapter': return {
      eligible: scroll > -250,
      wrapperClassName: 'transparent home',
      showLogo: scroll <= -250,
      showRightMenu: scroll <= -250,
      whiteLogo: scroll > -250,
    };

    default: return {
      eligible: false,
      wrapperClassName: '',
      showLogo: true,
      showRightMenu: true,
      whiteLogo: false,
    };
  }
};