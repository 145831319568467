
export const HEADER_PRODUCT_SEARCH = 'header-product-search';

export const shouldShowSearchBox = (pathname) => {
  if (pathname === '/shop/[prettyId]') {
    return HEADER_PRODUCT_SEARCH;
  }
};

export default shouldShowSearchBox;
