import React, { useEffect } from 'react';
import App from 'next/app';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import AppLayout from 'containers/LayoutContainer/AppLayout';
import { StickyProvider } from 'contexts/app/app.provider';
import { AuthProvider } from 'contexts/auth/auth.provider';
import { CartProvider } from 'contexts/cart/use-cart';
import { HeaderProvider } from 'contexts/header/header.provider';
import { LanguageProvider } from 'contexts/language/language.provider';
import { SearchProvider } from 'contexts/search/search.provider';
import { parseCookies } from 'helper/parse-cookies';
import { useDeviceType } from 'helper/useDeviceType';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'styled/global.style';
import { theme } from 'theme';
import Log from 'helper/sentry';
import { UAParser } from "ua-parser-js"
import qs from 'querystring';

import localEn from 'data/translation/en.json';
import Package from 'package.json';

import '@redq/reuse-modal/lib/index.css';
import 'components/MultiCarousel/MultiCarousel.style.css';
import 'rc-collapse/assets/index.css';
import 'rc-drawer/assets/index.css';
import 'rc-table/assets/index.css';
import 'react-google-places-autocomplete/dist/index.min.css';
import 'react-multi-carousel/lib/styles.css';

const tagManagerPromise = import('react-gtm-module').then(module => module.default);
const firebasePromise = import('firebase').then(module => module.default);
import IntercomWrapper from 'components/IntercomWrapper/IntercomWrapper';
import { dataLayer } from 'helper/gtm/helper';

// Language translation Config
const messages = {
  en: localEn,
};
// need to provide types
const ExtendedApp = function ExtendedApp({
  Component,
  pageProps,
  userAgent,
  locale,
  query,
}) {
  const deviceType = useDeviceType(userAgent);
  const environment = process.env.DEVO_ENV;
  const isLocal = !process.env.NEXT_PUBLIC_DEVO_ENV;

  useEffect(() => {
    console.log(`VERSION ${Package.version}`);
    Log.info(`DEVO_ENV ${environment}`, 'general', null);
  
    const parser = new UAParser();
    const result = parser.getResult();
    Log.info(`${result?.browser?.name}@${result?.browser?.version}`, 'general', result);
  }, []);

  // Sentry
  useEffect(() => {
    // GTM: https://medium.com/@rodolfo.3/sentry-for-react-gtm-and-express-41c869021990
    if (isLocal) return;

    const sampleRate = (environment === 'stable') ? 1.0 : 0.25;
    Log.info('Initializing Sentry...', 'sentry', { sampleRate });

    Sentry.init({
      dsn: process.env.SENTRY_URL,
      environment: environment,
      release: "devo-web-app@" + Package.version,
      debug: environment === 'stable',
      integrations: [new TracingIntegrations.BrowserTracing()],
      tracesSampleRate: sampleRate,
      beforeSend: function(event, hint) {
        var gtm = false;
        event.extra = event.extra || {};
        try {
          // @ts-ignore
          gtm = hint.originalException.stack.indexOf('www.googletagmanager.com/gtm.js?id=') > -1;
        } catch (error) {
          console.error(error);
        }
        event.tags = event.tags || {};
        if (gtm) {
          event.tags.logger = 'gtm';
          event.extra.dataLayer = dataLayer().map(
            function(i) {
              try {
                return JSON.stringify(i, null, ' ');
              } catch(error) {
                return '[cannot be serialized]: ' + error.message;
              }
            }
          );
        }
        event.tags.logger = event.tags.logger || '_default_';
        return event;
      }
    });
    Sentry.setContext("Devo", {
      env: environment,
    });
  }, []);

  // Google Tag Manager
  useEffect(() => {
    const initTagManager = async () => {
      const tagManager = await tagManagerPromise
      tagManager.initialize({ gtmId: process.env.GTM_ID })
    }
    if (isLocal) return;
    initTagManager()
  }, [])

  // Firebase
  useEffect(() => {
    if (isLocal) return;

    const config = {
      apiKey: process.env.FIREBASE_API_KEY,
      appId: process.env.FIREBASE_APP_ID,
      authDomain: process.env.FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.FIREBASE_DATABASE_URL,
      projectId: process.env.FIREBASE_PROJECT_ID,
      storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    }

    const loadFireBase = async () => {
      try {
        const firebase = await firebasePromise
        firebase.initializeApp(config)

        const remoteConfig = firebase.remoteConfig();
        var interval = ((): number => {
          if (isLocal) return 0;
          if (environment === 'stable') return 3600000;
          return 43200000;
        })();
        Log.info(`Setting remote config interval to ${interval}`, 'firebase', null);
        remoteConfig.settings = {
          fetchTimeoutMillis: interval,
          minimumFetchIntervalMillis: interval
        };
        remoteConfig.defaultConfig = {
          referral_promotion_enabled: true,
        };

        const response = await remoteConfig.fetchAndActivate();
        Log.info('RemoteConfig fetched and activated', 'firebase', response);
      } catch (e) {
        Log.warning('Failed to setup Firebase SDK', 'firebase', e);
      }
    }
    loadFireBase();
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={ErrorBoundary} showDialog>
      <ThemeProvider theme={theme}>
        <LanguageProvider messages={messages} initLocale={locale}>
          <CartProvider>
            <SearchProvider query={query}>
              <HeaderProvider>
                <StickyProvider>
                  <AuthProvider>
                    <>
                      <AppLayout {...pageProps} deviceType={deviceType}>
                        <Component {...pageProps} deviceType={deviceType} />
                        <IntercomWrapper deviceType={deviceType} />
                      </AppLayout>
                      <GlobalStyle />
                    </>
                  </AuthProvider>
                </StickyProvider>
              </HeaderProvider>
            </SearchProvider>
          </CartProvider>
        </LanguageProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
}

export default ExtendedApp;

const fetchBody = async (req, parse) => {
  let promise = await new Promise((resolve, reject) => {
    var data = '';
    req?.on('data', chunk => data += chunk);
    req?.on('end', () => data.length === 0 ? resolve(null) : resolve(parse ? parse(data) : data) )
  })
  .catch(err => {throw err});
  return promise;
};

ExtendedApp.getInitialProps = async (appContext) => {
  var appProps = await App.getInitialProps(appContext);
  const { req, query } = appContext.ctx;
  const userAgent = req ? req.headers['user-agent'] : navigator.userAgent;
  const { locale } = parseCookies(req);

  if (req?.url === '/auth/apple') appProps.pageProps.body = await fetchBody(req, qs.parse);

  return { ...appProps, userAgent, query, locale };
};
