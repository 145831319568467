import styled from 'styled-components';

export const Wrapper = styled.a`
  height: 40px;
  border: 1px #e8ebeb solid;
  border-radius: 20px;
  margin-right: 25px;
  padding: 9px 16px;
  cursor: pointer;
  background-color: white;

  &:hover {
    background-color: #f7f7f7;
  }

  &.mobile {
    margin-right: 0;
  }
`;

export const ImageWrapper = styled.div`
  display: inline-block;

  span {
    color: #EE504F;
  }

  svg {
    margin-bottom: -1px;
  }
`;

export const PriceWrapper = styled.div`
  display: inline-block;
  margin-left: 8px;
`;

export const TotalPrice = styled.span`
  color: #000;
`;
