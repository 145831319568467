import React from 'react';
import { ProductSearchBox, SearchButton, SearchInputWrapper } from './SearchBox.style';
import { FormattedMessage, useIntl } from 'react-intl';

type InputProps = {
  type?: string;
  value?: number | string;
  buttonText?: string;
  inputClass?: string;
  buttonIcon?: React.ReactNode;
  placeholder?: string;
  bordered?: boolean;
  showSvg?: boolean;
  style?: React.CSSProperties;
  onChange?: (e: any) => void;
  onKeyPress?: (e: any) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  onClick?: (e: any) => void;
};

const InputSearch: React.FC<InputProps> = ({
  type,
  value,
  buttonText,
  buttonIcon,
  onChange,
  style,
  onKeyPress,
  onBlur,
  onClick,
  onFocus,
  bordered,
  showSvg,
  inputClass,
  placeholder
}) => {
  const intl = useIntl();
  return (
    <>
      <SearchInputWrapper
        style={style}
        bordered={bordered}
        showSvg={showSvg}
        className={`${inputClass} ${bordered === true ? 'bordered' : ''}`}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {showSvg && (
            <span className="searchIcon" onClick={onClick}>
              {buttonIcon}
            </span>
          )}
          <ProductSearchBox>
            <input
              type={type}
              value={value}
              placeholder={placeholder}
              onChange={onChange}
              onFocus={onFocus}
              onKeyPress={onKeyPress}
              onBlur={onBlur}
            />
          </ProductSearchBox>
        </form>
      </SearchInputWrapper>
    </>
  );
};
export default InputSearch;
