import * as Sentry from '@sentry/react';
import React, { useContext, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Sticky from 'react-stickynode';
import Log from 'helper/sentry'
import { getRefreshExpiryTime } from 'helper/user'
import Header from './Header/Header';
import { LayoutWrapper } from './Layout.style';
import { AuthContext } from 'contexts/auth/auth.context';
import { withApollo } from 'helper/apollo';
import { usePrevious } from 'helper/hooks';
import { toHHMMSS } from 'helper/data/string';
import { identifyToMixpanel, setMixpanelUserProperty } from 'helper/gtm/helper';
import Package from 'package.json';
const MobileHeader = dynamic(() => import('./Header/MobileHeader'), {
  ssr: false,
});

type LayoutProps = {
  className?: string;
  deviceType: {
    mobile: boolean;
    tablet: boolean;
    desktop: boolean;
  };
  token?: string;
};

const Layout: React.FunctionComponent<LayoutProps> = ({
  className,
  children,
  deviceType: { mobile, tablet, desktop },
  token,
}) => {
  const isSticky = true;
  const { pathname } = useRouter();
  const { state, authDispatch } = useContext<any>(AuthContext);

  useEffect(() => {
    window['cookieconsent']?.initialise({});
  }, []);

  useEffect(() => {
    const expiry = getRefreshExpiryTime();
    if (expiry) Log.info(`Logged in, expiring in ${toHHMMSS(expiry)}`, 'session', null);

    fetch('https://ipv4.jsonip.com').then(res => res.json()).then(data => {
      setMixpanelUserProperty({
        '$ip': data?.ip || '',
        'platform': 'Web',
        'version': Package.version,
        'last_session': (new Date()).toISOString(),
      });
    });

    return () => {
      setMixpanelUserProperty({ 'last_session' : (new Date()).toISOString() });
      identifyToMixpanel();
    };
  }, []);

  const prevPathname = usePrevious(pathname);
  useEffect(() => {
    if (!process.browser) return;
    if (prevPathname !== pathname) newPageLoaded();
  }, [pathname]);

  const newPageLoaded = () => {
    window.scrollTo(0, 0);
    
    // Identify needs to be called every time you make a people.set call — to either create or update a people profile within Mixpanel.
    // https://community.mixpanel.com/sending-data-to-mixpanel-11/how-to-implement-people-set-in-js-api-585
    identifyToMixpanel();
  };

  return (
    <LayoutWrapper className={`layoutWrapper ${className}`}>
      {(mobile || tablet) && (
        <Sticky enabled={isSticky} innerZ={1001}>
          <MobileHeader
            className={`${isSticky ? 'sticky' : 'unSticky'}`}
            pathname={pathname}
          />
        </Sticky>
      )}

      {desktop && (
        <Sticky enabled={isSticky} innerZ={1001}>
          <MobileHeader
            className={`${isSticky ? 'sticky' : 'unSticky'} desktop`}
            pathname={pathname}
          />
          <Header
            className={`${isSticky ? 'sticky' : 'unSticky'}`}
            token={token}
            pathname={pathname}
          />
        </Sticky>
      )}
      {children}
    </LayoutWrapper>
  );
};

export default Sentry.withProfiler(withApollo(Layout));
