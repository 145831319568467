import React from 'react';
import { LeftMenuBox } from './LeftMenu.style';
import Logo from 'components/Logo/Logo';

type Props = {
  logo: string;
};

export const LeftMenu: React.FC<Props> = ({ logo }) => {
  return (
    <LeftMenuBox>
      <Logo
        imageUrl={logo}
        alt={'Devo Logo'}
      />
    </LeftMenuBox>
  );
};
