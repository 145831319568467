import React, { useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import ProductSearchBox from 'components/SearchBox/ProductSearchBox';
import { SearchContext } from 'contexts/search/search.context';
import { AuthContext } from 'contexts/auth/auth.context';
import { RightMenu } from './Menu/RightMenu/RightMenu';
import { LeftMenu } from './Menu/LeftMenu/LeftMenu';
import HeaderWrapper from './Header.style';
import UserImage from 'image/user.jpg';
import shouldShowSearchBox, { HEADER_PRODUCT_SEARCH } from '../header-search';

import LogoImage from 'image/Devo.png';
import LogoImageWhite from 'image/Devo-white.png';

import { useCart } from 'contexts/cart/use-cart';
import { getCurrentUserUuid, logout } from 'helper/user';
import { useScrollPosition } from 'helper/useScrollPosition';
import { useHeaderStyle } from 'helper/views/header';
import { HOME_ROUTE, LOGIN_ROUTE, REGISTER_ROUTE } from 'constants/navigation';
import { logAnalytics } from 'helper/gtm/helper';

type Props = {
  className?: string;
  token?: string;
  pathname?: string;
};

const Header: React.FC<Props> = ({ className }) => {
  const router = useRouter();
  const { clearCart, removeCoupon } = useCart();
  const { authDispatch } = useContext<any>(AuthContext);
  const { state, dispatch } = React.useContext(SearchContext);
  const { pathname } = useRouter();

  const [typingTimeout, setTypingTimeout] = React.useState(0);

  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  // AUTHENTICATION

  const handleLogout = () => {
    if (typeof window === 'undefined') { return };
    logAnalytics('logout', { action: 'user_action' });
    logout(() => {
      authDispatch({ type: 'SIGN_OUT' });
      router.push(HOME_ROUTE);
      clearCart();
      removeCoupon();
    });
  };

  // SEARCH

  const onSearchChange = (text: any) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(setTimeout(() => {
      changeSearchState(text);
    }, 500));
  };

  const changeSearchState = (text: string) => {
    if (text && text.length > 0) {
      dispatch({ type: 'UPDATE_SEARCH', payload: text });
    } else {
      dispatch({ type: 'UPDATE_SEARCH', payload: '' });
    }
  };

  // RENDER

  const headerType = shouldShowSearchBox(pathname);

  // Transparent Header
  const [headerStyle, setHeaderStyle] = useState(useHeaderStyle(pathname, 0));
  useScrollPosition(({ currPos }) => {
    setHeaderStyle(useHeaderStyle(pathname, currPos.y));
  }, [headerStyle], null, false, 300);
  const isTransparent = headerStyle?.eligible === true;
  const wrapperClassName = headerStyle?.wrapperClassName || '';

  useEffect(() => {
    setHeaderStyle(useHeaderStyle(pathname, 0));
  }, [pathname]);

  return (
    <HeaderWrapper className={`${className} ${isTransparent ? wrapperClassName : ''}`}>
      {headerStyle?.showLogo === true &&
        <LeftMenu logo={headerStyle?.whiteLogo ? LogoImageWhite : LogoImage} />
      }

      {state.shop && headerType == HEADER_PRODUCT_SEARCH &&
        <ProductSearchBox
          value={state.search || ''}
          onClick={onSearchChange}
          handleSearch={onSearchChange}
          placeholder={`Search ${state.shop.name}...`}
          className='banner-search'
        />
      }
      
      {mounted && headerStyle?.showRightMenu &&
        <RightMenu
          isAuthenticated={getCurrentUserUuid() != null}
          onLogin={() => {
            router.push(`${LOGIN_ROUTE}?redirect=${window.location.pathname}`)
            logAnalytics('account_nav_login');
          }}
          onRegister={() => {
            router.push(`${REGISTER_ROUTE}?redirect=${window.location.pathname}`)
            logAnalytics('account_nav_signup');
          }}
          onLogout={handleLogout}
          avatar={UserImage}
        />
      }
    </HeaderWrapper>
  );
};

export default Header;
