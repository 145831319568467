import React, { useState } from 'react';
import Popover from 'components/Popover/Popover';
import { AuthorizedMenu } from '../AuthorizedMenu';
import {
  RightMenuBox,
  LoginButton,
  RegisterButton
} from './RightMenu.style';
import HeaderBasket from 'components/HeaderBasket/HeaderBasket';

type Props = {
  onLogin: () => void;
  onRegister: () => void;
  onLogout: () => void;
  avatar: string;
  isAuthenticated: boolean;
};

export const RightMenu: React.FC<Props> = ({
  onLogin,
  onRegister,
  onLogout,
  avatar,
  isAuthenticated,
}) => {
  const [popoverState, setPopoverState] = useState(false);
  
  return (
    <RightMenuBox>
      {!isAuthenticated ? (
        <>
          <HeaderBasket />
          <LoginButton
            className='login'
            onClick={onLogin}
            size="small"
            title="Login"
          />
          <RegisterButton
            className='register'
            onClick={onRegister}
            size="small"
            title="Sign up"
          />
        </>
      ) : (
        <>
          <HeaderBasket />
          <Popover
            state={popoverState}
            onToggle={setPopoverState}
            direction="right"
            className="user-pages-dropdown"
            handler={
              <img src={avatar} alt="user" />
            }
            content={
              <AuthorizedMenu 
                onLinkClick={() => setPopoverState(false) }
                onLogout={onLogout} 
              />
            }
          />
        </>
      )}
    </RightMenuBox>
  );
};
